.main {
  margin-top: 130px;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  background: #000;
  .flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    .leftSection {
      display: flex;
      flex-direction: column;
      color: #b6b6c2;
      font-size: 10px;
      font-weight: 480;
      max-width: 200px;
      .logo {
        width: 49.514px;
        height: 49.512px;
      }
      .beyond {
        margin: 10px 0;
        width: 134.641px;
        height: 23.863px;
      }
    }
    .rightSection {
      display: flex;
      flex-direction: column;
      color: #b6b6c2;
      font-size: 14px;
      font-weight: 530;
      a {
        text-decoration: none;
        color: #b6b6c2;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
      span {
        margin: 6px 0;
      }
      button {
        border-radius: 12px;
        background: linear-gradient(209deg, #3b5ee5 0%, #00147d 100%);
        width: 108px;
        height: 31px;
        border: none;
        color: #ffffff;
        transition: 250ms;
        margin-left: 10px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .copyrights {
    padding: 10px 0;
    color: rgba(182, 182, 194, 0.47);
    text-align: center;
    font-size: 10px;
    font-weight: 480;
  }
}
@media only screen and (max-width: 900px) {
  .main {
    margin-top: 60px;

    .flexBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      .leftSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo {
          width: 49px;
          height: 49px;
        }
        .beyond {
          margin: 10px 0;
          width: 134px;
          height: 23px;
        }
      }
      .rightSection {
        margin-top: 20px;
        align-items: center;
      }
    }
  }
}
