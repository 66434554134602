.main {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  .logo {
    img {
      height: 31px;
      width: 116px;
    }
  }
  .right {
    button {
      border-radius: 12px;
      background: linear-gradient(209deg, #3b5ee5 0%, #00147d 100%);
      width: 108px;
      height: 31px;
      border: none;
      color: #ffffff;
      transition: 250ms;
      margin-left: 10px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
