@import "~bootstrap/scss/bootstrap";
@font-face {
  font-family: "General Sans Variable";
  src: url("/assets/fonts/GeneralSans/GeneralSans-Variable.ttf")
    format("truetype");
  font-weight: 100 900;
}

body {
  font-family: "General Sans Variable";
  font-weight: 520;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000212;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alert {
  width: fit-content;
  right: 0;
  position: fixed;
  top: 0px;
}
