.bg {
  background-image: url("/assets/hero/bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin-top: -64px;
  .main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    .tag {
      font-size: 14px;
      border-radius: 72px;
      border: 1px solid #37374d;
      font-weight: 480;
      background: linear-gradient(
        181deg,
        #292a41 0%,
        rgba(41, 42, 65, 0.46) 100%
      );
      padding: 6px 20px;
    }
    h1 {
      margin: 20px 0;
      max-width: 630px;
      text-align: center;
      font-weight: 520;
      font-size: 64px;
      background: linear-gradient(164deg, #fff 0%, #c1c1c7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      max-width: 600px;
      color: #b5c0da;
      text-align: center;
      font-weight: 480;
      font-size: 22px;
    }
    .subscribeBox {
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 9px;
      border: 1.106px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.06);
      box-shadow: 0px 0px 53px 0px rgba(177, 187, 255, 0.05);
      backdrop-filter: blur(7px);
      .inputBox {
        background-color: transparent;
        border-radius: 9px;
        border: none;
        color: #ffffff;
        width: 400px;
        &:focus {
          outline: none;
        }
      }
      button {
        border: none;
        padding-left: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        background: transparent;
        color: #ffffff;
        font-weight: 530;
        text-decoration: underline;
        transition: 250ms;
        &:hover {
          opacity: 0.8;
        }
        &:disabled {
          opacity: 0.3;
        }
      }
    }
    .error {
      color: red;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .bg {
    .main {
      h1 {
        margin: 20px 0;
        font-size: 44px;
      }
      p {
        font-size: 20px;
      }
      .subscribeBox {
        width: 100%;
        padding: 10px 20px;
        .inputBox {
          width: 60%;
          &:focus {
            outline: none;
          }
        }
        button {
          padding-left: 10px;
        }
      }
    }
  }
}
